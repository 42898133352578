import withWidth from '@material-ui/core/withWidth';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useCommonStyle from '../../styles/CommonStyle';
import Pool from './Pool';
import useStyles from './style';
import CardCompletedSales from '../Dashboard/CardCompletedSales';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getApiUrl } from '../../types/getEnv';

const envLocal = localStorage?.getItem('env');
const env = envLocal ? JSON?.parse(envLocal) : {};

const iconSearch = '/images/icons/search.svg';

const tableHeader = ['Pool Name', 'Network', 'IDO Price', 'Current Price', 'ATH IDO ROI USD', 'Type', 'Market Maker', 'Status'];

const baseUrl = getApiUrl();

const Pools = (props: any) => {
    const styles = useStyles();
    const commonStyle = useCommonStyle();
    const { data: appChain } = useSelector((state: any) => state.appNetwork);
    const { data: connector } = useSelector((state: any) => state.connector);

    const [input, setInput] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [pools, setPools] = useState([]);
    const [loadingSetPool, setLoadingSetPool] = useState<boolean>(false);
    const isTablet = useMediaQuery('(min-width: 960px)');

    const { data: poolsList, loading: loadingGetPool } = useFetch<any>(`/pools?page=${currentPage}&limit=10&title=${input}`);

    const handleInputChange = debounce((e: any) => {
        Promise.resolve().then(() => {
            setInput(e.target.value);
            setCurrentPage(1);
        });
    }, 500);

    useEffect(() => {
        const manipulatePoolsData = async () => {
            setLoadingSetPool(true);
            setTotalPage(poolsList.lastPage);
            setCurrentPage(poolsList.page);

            let listData = poolsList.data;
            let poolWithStatus: any[] = [];
            let symbolList = listData.map((dt: any) => {
                return dt.symbol;
            });

            if (symbolList.length > 0) {
                const searchParams = symbolList.join(',');
                await axios
                    .get(`${baseUrl}/token-price?page=0&search=${searchParams}`)
                    .then((response) => {
                        for (let i = 0; i < listData.length; i++) {
                            const pool = { ...listData[i] };
                            let tokenPrice = response.data.data.data.find((dt: any) => dt.token_symbol === pool.symbol.toUpperCase());
                            poolWithStatus.push({
                                ...pool,
                                current_price: tokenPrice && Number(tokenPrice.current_price),
                                ath_roi: tokenPrice && Number(tokenPrice.ath_roi),
                                status: pool.campaign_status || pool.campaignStatus,
                            });
                        }
                    })
                    .catch((e) => {
                        for (let i = 0; i < listData.length; i++) {
                            const pool = { ...listData[i] };
                            poolWithStatus.push({
                                ...pool,
                                status: pool.campaign_status || pool.campaignStatus,
                            });
                        }
                    });
            }

            poolsList.data = poolWithStatus;
            listData = poolWithStatus;
            setLoadingSetPool(false);
            setPools(listData);
        };

        poolsList && poolsList.data && manipulatePoolsData();
    }, [poolsList, appChain, connector]);

    const renderTable = () => {
        if (!isTablet) {
            return (
                <div className={styles.tableMobileContainer}>
                    {pools.length > 0 &&
                        pools.map((pool: any, index: number) => {
                            return <CardCompletedSales pool={pool} key={index} loading={loadingGetPool || loadingSetPool} />;
                        })}
                </div>
            );
        }

        return (
            <TableContainer component={Paper} className={styles.tableContainer}>
                <Table className={styles.table} aria-label='simple table'>
                    <TableHead className={styles.tableHeaderWrapper}>
                        <TableRow>
                            {tableHeader.map((header: any, index: number) => {
                                return <TableCell key={index}>{header}</TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pools.length > 0 &&
                            pools.map((pool: any, index: number) => {
                                return <Pool pool={pool} key={index} loading={loadingGetPool || loadingSetPool} />;
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <>
            <div className={styles.poolsContainer}>
                <div className={styles.listPoolHeader}>
                    <h1 className={styles.listPoolTitle}>List View</h1>
                    <button className={styles.btnCalender} onClick={() => props.history.push('/calendar')}>
                        <img src='/images/icons/calendar.svg' width={14} height={14} alt='' />
                        <span>Calendar view</span>
                    </button>
                    <div className={styles.searchGroup}>
                        <input type='text' placeholder='Search by Pool name, Token Symbol' className={commonStyle.nnn1424h} onChange={handleInputChange} />
                        <img src={iconSearch} alt='' />
                    </div>
                </div>

                {renderTable()}

                {totalPage > 1 && (
                    <Pagination
                        count={totalPage}
                        shape='rounded'
                        style={{ marginTop: 30 }}
                        className={styles.pagination}
                        onChange={(e: any, value: any) => {
                            if (!loadingGetPool) {
                                setCurrentPage(value);
                            }
                        }}
                        page={currentPage}
                        renderItem={(item: any) => <PaginationItem className='pagination-item' {...item} />}
                    />
                )}
            </div>
        </>
    );
};

export default withWidth()(withRouter(Pools));
