/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumbs,
  Menu,
  MenuItem,
  MenuProps,
  styled,
  Typography,
} from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Skeleton from "@material-ui/lab/Skeleton";
import { isNumber } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import {
  connectorNames,
  connectorsByName,
} from "../../../constants/connectors";
import { APP_NETWORKS_SUPPORT } from "../../../constants/network";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../services/axios";
import { getUserTier } from "../../../store/actions/sota-tiers";
import { WalletConnectionState } from "../../../store/reducers/wallet";
import { trimMiddlePartAddress } from "../../../utils/accountAddress";
import { getConfigHeader } from "../../../utils/configHeader";
import ButtonLink from "../ButtonLink";
import AppNetworkSwitch from "./AppNetworkSwitch";
import ConnectWalletModal from "./ConnectWalletModal";
import { HeaderContext } from "./context/HeaderContext";
import SignRequiredModal from "./SignRequiredModal";
import useStyles from "./styles";
import WalletDisconnect from "./WalletDisconnect";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { breadcrumbType } from "../../../constants";
import { menuAccount } from "../../../pages/AccountV2";
import usePoolDetails from "../../../hooks/usePoolDetails";

const logo = "/images/landing/logo.png";
const iconClose = "/images/icons/close.svg";
const BnbIcon = "/images/BNB.png";

interface Props extends MenuProps {
  vertical?: number | "center" | "bottom" | "top";
  horizontal?: number | "center" | "left" | "right";
}

export const StyledMenu = styled((props: Props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: props?.vertical ? props?.vertical : "top",
      horizontal: props?.horizontal ? props?.horizontal : "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: 236,
    color: "#fff",
    backgroundColor: "#212126",
    borderRadius: "2px",
    border: "1px solid #353539",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiList-padding": {
      padding: "0",
    },
    "& .MuiListItem-gutters": {
      padding: "14px 20px",
      font: "14px/24px ",
    },
    "& .MuiMenuItem-root": {
      borderBottom: "1px solid #44454B",

      "&:last-child": {
        borderBottom: "0",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      "&:hover": {
        backgroundColor: "#3C3C3C",
      },
    },
  },
}));

type HeaderLinks = {
  [key: string]: string;
};

export const HEADER_LINKS: HeaderLinks = {
  POOL: "/pools",
  STAKING: "/staking-pools",
  ACCOUNT: `/account/${menuAccount?.my_profile?.key}`,
  LEARN: "https://rwa.org/blog",
};

export const LEARN_LINKS = [
  {
    name: "Introduction & Overview",
    url: "https://docs.rwa.inc/launchpad/introduction-and-overview",
  },
  {
    name: "Staking Overview",
    url: "https://docs.rwa.inc/staking-overview/rwa-staking-policies",
  },
  {
    name: "Tier System and Staking Rewards",
    url: "https://docs.rwa.inc/tier-system-and-staking-rewards/the-rwa-launchpad-tier-system",
  },
  {
    name: "Refund Policy",
    url: "https://docs.rwa.inc/refund-policy/user-refund-policy",
  },
  {
    name: "User FAQs",
    url: "https://docs.rwa.inc/faq/user-faq#user-faqs",
  },
  {
    name: "Project FAQs",
    url: "https://docs.rwa.inc/faq/user-faq#project-faqs",
  },
];

export const BUY_RWA_LINK = [
  {
    link: "https://uniswap.org/",
    name: "Uniswap",
    img: "/images/landing/uniswap.svg",
  },
  {
    link: "https://www.gate.io",
    name: "Gate.io",
    img: "/images/landing/gate.svg",
  },
  {
    link: "https://www.kucoin.com",
    name: "Kucoin",
    img: "/images/landing/kucoin.svg",
  },
];

const HeaderDefaultLayout: React.FC<any> = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname: string = location?.pathname || "";
  const { refreshing } = useSelector((state: any) => state.tokensByUser);

  const [switchNetworkDialog, setSwitchNetworkDialog] =
    useState<boolean>(false);
  const [disconnectDialog, setDisconnectDialog] = useState<boolean>(false);
  const [agreedTerms, setAgreedTerms] = useState<boolean>(false);
  const { appChainID } = useSelector((state: any) => state.appNetwork).data;
  const walletsInfo = useSelector((state: any) => state.wallet).entities;
  const [openSideBar, setOpenSideBar] = useState(false);
  const { data: message = "" } = useSelector((state: any) => state.messages);

  const [logoLoading, setLogoLoading] = useState<boolean>(true);
  const [anchorLearnMenu, setAnchorLearnMenu] = useState<null | HTMLElement>(
    null
  );
  const [anchorBuyMenu, setAnchorBuyMenu] = useState<null | HTMLElement>(null);
  const isOpenLearnMenu = Boolean(anchorLearnMenu);
  const isOpenBuyMenu = Boolean(anchorBuyMenu);
  const [mobileOpenLearnMenu, setMobileOpenLearnMenu] =
    useState<boolean>(false);
  const [mobileOpenBuyMenu, setMobileOpenBuyMenu] = useState<boolean>(false);

  const [chainLogo, setChainLogo] = useState<String>(BnbIcon);
  const [chainName, setChainName] = useState<String>("BSC Mainnet");
  const [chainCurrency, setChainCurrency] = useState<String>("BNB");
  const [isKyc, setIsKyc] = useState<boolean>(false);
  const [loadingIsKyc, setLoadingIsKyc] = useState<boolean>(true);
  const isTablet = useMediaQuery("(min-width: 990px)");
  const [isShowBanner, setIsShowBanner] = useState<string | null>(
    localStorage.getItem("isShowBanner")
  );

  const listSubpath = useMemo(() => {
    const pathArr = pathname?.split("/") || [];
    const pathList = pathArr?.map((path) => {
      return {
        name: breadcrumbType?.[path] ? breadcrumbType?.[path]?.name : path,
        url: breadcrumbType?.[path] ? breadcrumbType?.[path]?.url : path,
        isDisable: breadcrumbType?.[path]
          ? breadcrumbType?.[path]?.isDisable
          : true,
      };
    });
    return pathList;
  }, [pathname]);

  useEffect(() => {
    setOpenSideBar(false);
  }, [pathname]);

  const { isAuth, connectedAccount, wrongChain } = useAuth();

  useEffect(() => {
    if (isAuth && connectedAccount && !wrongChain) {
      dispatch(getUserTier(connectedAccount));
    }
  }, [isAuth, wrongChain, connectedAccount, dispatch]);

  const {
    handleProviderChosen,
    currentConnector,
    walletName,
    setWalletName,
    loginError,
    currentConnectedWallet,
    setCurrentConnectedWallet,
    openConnectWallet,
    setOpenConnectWallet,
    connectWalletLoading,
  } = useContext(AppContext);

  useEffect(() => {
    const getUserProfile = async () => {
      let configHeader = getConfigHeader(connectedAccount);
      setLoadingIsKyc(true);
      const response = (await axios.get(`/user/profile`, configHeader)) as any;
      setLoadingIsKyc(false);

      if (response?.status && response.status === 200 && response.data) {
        setIsKyc(!!response?.data?.data?.user?.is_kyc);
      }
    };
    connectedAccount && getUserProfile();
  }, [connectedAccount]);

  const currentAccount =
    currentConnectedWallet && currentConnectedWallet.addresses[0];
  const balance = currentConnectedWallet
    ? currentConnectedWallet.balances[currentAccount]
    : 0;
  const handleConnectWalletClose = () => {
    setOpenConnectWallet && setOpenConnectWallet(false);
  };

  const handleConnectWalletOpen = () => {
    setOpenConnectWallet && setOpenConnectWallet(true);
    setOpenSideBar(false);
  };

  const handleDisconnectDialogOpen = () => {
    setDisconnectDialog(true);
    setOpenSideBar(false);
  };

  useEffect(() => {
    if (walletsInfo && walletName) {
      let currentWalletsName: string[] = [];
      let isFound = false;

      Object.keys(walletsInfo).forEach((key) => {
        const wallet = walletsInfo[key];

        if (
          wallet.addresses.length > 0 &&
          wallet.connectionState === WalletConnectionState.CONNECTED &&
          !isFound
        ) {
          isFound = true;
          setCurrentConnectedWallet && setCurrentConnectedWallet(wallet);
          currentWalletsName.push(key);
        }
      });

      if (
        currentWalletsName.length > 0 &&
        walletName.length === 0 &&
        !currentConnector
      ) {
        const chooseWallet = currentWalletsName[0] as connectorNames;

        setWalletName && setWalletName(currentWalletsName);
        handleProviderChosen &&
          handleProviderChosen(chooseWallet, connectorsByName[chooseWallet]);
      }
    }
  }, [walletsInfo, walletName]);

  useEffect(() => {
    const networkInfo = APP_NETWORKS_SUPPORT[Number(appChainID)];
    if (!networkInfo) {
      return;
    }

    setChainLogo(networkInfo.icon || BnbIcon);
    setChainName(networkInfo.name || "BSC Mainnet");
    setChainCurrency(networkInfo.currency || "BNB");
  }, [appChainID]);

  const getClassActive = (path: string) => {
    if (path !== pathname) return "";
    return styles.linkActived;
  };
  const openLearnMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLearnMenu(event.currentTarget);
    if (isMobile) setMobileOpenLearnMenu((prev) => !prev);
  };

  const openBuyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorBuyMenu(event.currentTarget);
    if (isMobile) setMobileOpenBuyMenu((prev) => !prev);
  };
  const handleCloseLearnMenu = (index: number) => {
    setAnchorLearnMenu(null);
    // @ts-ignore
    isNumber(index) && window.open(LEARN_LINKS[index].url, "_blank").focus();
  };

  const handleCloseBuyMenu = (index: number) => {
    setAnchorBuyMenu(null);
    // @ts-ignore
    isNumber(index) && window.open(BUY_RWA_LINK[index].link, "_blank").focus();
  };

  const renderButtonPool = () => {
    return (
      <ButtonLink
        text="Launches"
        to="/pools"
        className={`${styles.btnHeader} ${getClassActive(HEADER_LINKS.POOL)}`}
        onClick={() => {
          const el = document.getElementById("pools");
          if (el) el.scrollIntoView({ behavior: "smooth" });
        }}
      />
    );
  };
  const renderButtonStaking = () => {
    return (
      <ButtonLink
        text="Staking"
        to={HEADER_LINKS.STAKING}
        className={`${styles.btnHeader} ${getClassActive(
          HEADER_LINKS.STAKING
        )}`}
      />
    );
  };
  const renderButtonLearn = () => {
    return (
      <div className={styles.learnBtn}>
        <a onClick={openLearnMenu} className={styles.btnHeader}>
          <p style={{ paddingBottom: "4px" }}>
            <span>Learn</span>
          </p>
          <ArrowDropDown style={{ fill: "#000B60" }} />
        </a>
        {isMobile ? (
          mobileOpenLearnMenu && (
            <div className={styles.menuLearnMobile}>
              {LEARN_LINKS.map((item: any, index: number) => {
                return (
                  <a
                    key={index}
                    href={item.url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {item.name}
                  </a>
                );
              })}
            </div>
          )
        ) : (
          <StyledMenu
            id="learn-menu"
            MenuListProps={{
              "aria-labelledby": "learn-menu-button",
            }}
            anchorEl={anchorLearnMenu}
            open={isOpenLearnMenu}
            className={styles.learnMenu}
            onClose={handleCloseLearnMenu}
          >
            {LEARN_LINKS.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  href={item.url}
                  target={"_blank"}
                  style={{ fontFamily: "" }}
                  onClick={() => handleCloseLearnMenu(index)}
                  disableRipple
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </StyledMenu>
        )}
      </div>
    );
  };

  const renderButtonBuyRWA = () => {
    return (
      <div className={styles.learnBtn}>
        {/* <a onClick={openBuyMenu} className={styles.btnHeader}>
          <p style={{ paddingBottom: "4px" }}>
            <span>Buy $RWA</span>
          </p>
          <ArrowDropDown style={{ fill: "#000B60" }} />
        </a> */}
        {isMobile ? (
          mobileOpenBuyMenu && (
            <div className={styles.menuLearnMobile}>
              {BUY_RWA_LINK.map((item: any, index: number) => {
                return (
                  <div key={index} className="launchpad-support">
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <div>
                        <img
                          src={item.img}
                          width={20}
                          height={20}
                          alt="uniswap"
                        />
                        <span>{item.name}</span>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          )
        ) : (
          <StyledMenu
            id="buy-menu"
            MenuListProps={{
              "aria-labelledby": "buy-menu-button",
            }}
            anchorEl={anchorBuyMenu}
            open={isOpenBuyMenu}
            className={styles.learnMenu}
            onClose={handleCloseBuyMenu}
          >
            {BUY_RWA_LINK.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  href={item.link}
                  target={"_blank"}
                  style={{
                    fontFamily: "",
                  }}
                  className="menu-item"
                  onClick={() => handleCloseBuyMenu(index)}
                  disableRipple
                >
                  <a
                    key={index}
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div
                      style={{
                        display: "flex ",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={item.img}
                        width={20}
                        height={20}
                        alt={item.name}
                      />
                      <span style={{ color: "#000B60" }}>{item.name}</span>
                    </div>
                  </a>
                </MenuItem>
              );
            })}
          </StyledMenu>
        )}
      </div>
    );
  };

  const renderButtonMyAccount = () => {
    if (!currentAccount) return null;
    return (
      <Link to={`/account/${menuAccount?.my_profile?.key}`}>
        <button className={styles.btnMyAccount}>
          <img src="/images/account_img.svg" alt="my account" />
          <div>My Account</div>
        </button>
      </Link>
    );
  };

  const renderButtonNetwork = () => {
    return (
      <button
        className={styles.grayBtn}
        onClick={() => {
          setSwitchNetworkDialog(true);
          setOpenSideBar(false);
        }}
      >
        {" "}
        <div style={{ alignItems: "center", display: "flex" }}>
          <img src={`${chainLogo}`} width={14} height={14} alt="" />
          <span className={styles.btnConnectText}>{chainName}</span>
        </div>
      </button>
    );
  };

  const renderButtonConnect = () => {
    return (
      <button
        className={!currentAccount ? styles.btnGradient : styles.btnConnected}
        onClick={() => {
          if (!connectWalletLoading) {
            !currentAccount
              ? handleConnectWalletOpen()
              : handleDisconnectDialogOpen();
          }
        }}
        disabled={connectWalletLoading}
      >
        <>
          {currentAccount && (
            <div className={styles.btnBalance}>
              {currentAccount &&
                (!loginError ? `${balance} ${chainCurrency}` : "0")}
            </div>
          )}
          {/* {!currentAccount && (
                    <img src={`/images/${WalletIcon}`} alt="wallet" />
                  )} */}
          <div
            className={
              currentAccount ? styles.btnAccount : styles.btnConnectText
            }
          >
            <div>
              {(currentAccount && `${trimMiddlePartAddress(currentAccount)}`) ||
                "Connect Wallet"}
            </div>
          </div>
        </>
      </button>
    );
  };

  const renderError = () => {
    return (
      <>
        {loginError && isKyc && (
          <div className={styles.loginErrorBanner}>
            <img
              src="/images/white_warning.svg"
              alt="white_warning icon"
              className={styles.iconWarning}
            />
            <div className={styles.loginErrorBannerText}>
              {loginError} Learn how to &nbsp;
              <a
                href="https://help.1inch.exchange/en/articles/4966690-how-to-use-1inch-on-bsc-binance-smart-chain"
                target="_blank"
                className={styles.loginErrorGuide}
                rel="noreferrer"
              >
                change network in wallet
              </a>
              &nbsp; or &nbsp;
              <button
                className={styles.btnChangeAppNetwork}
                onClick={() => {
                  setOpenSideBar(false);
                  setSwitchNetworkDialog(true);
                }}
              >
                Change App Network
              </button>
            </div>
          </div>
        )}
        {window.location.href.indexOf("buy-token") > -1 &&
          !loginError &&
          message != "" && (
            <div className={styles.loginErrorBanner}>
              <img
                src="/images/white_warning.svg"
                alt="white_warning icon"
                className={styles.iconWarning}
              />
              <span className={styles.loginErrorBannerText}>
                {message}&nbsp;&nbsp;
                {message === "Please connect wallet." ? (
                  <button
                    className={styles.btnChangeAppNetwork}
                    onClick={() => handleConnectWalletOpen()}
                  >
                    Connect Wallet
                  </button>
                ) : (
                  <button
                    className={styles.btnChangeAppNetwork}
                    onClick={() => {
                      setOpenSideBar(false);
                      setSwitchNetworkDialog(true);
                    }}
                  >
                    Change App Network
                  </button>
                )}
              </span>
            </div>
          )}
        {window.location.href.indexOf("calendar") > -1 &&
          !loginError &&
          message != "" && (
            <div className={styles.loginErrorBanner}>
              <img
                src="/images/white_warning.svg"
                alt="white_warning icon"
                className={styles.iconWarning}
              />
              <span className={styles.loginErrorBannerText}>
                {message}&nbsp;&nbsp;
                <button
                  className={styles.btnChangeAppNetwork}
                  onClick={() => handleConnectWalletOpen()}
                >
                  Connect Wallet
                </button>
              </span>
            </div>
          )}
      </>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <Link to={"/"} className={styles.navbarLogo}>
          <img
            onLoad={() => setLogoLoading(false)}
            style={logoLoading ? { display: "none" } : {}}
            src={logo}
            width={95}
            height={40}
            alt="logo"
          />
        </Link>
        {renderButtonPool()}
        {renderButtonStaking()}
        {renderButtonLearn()}
        {renderButtonBuyRWA()}
        <div className={styles.spacer}></div>
        {renderButtonNetwork()}
        {renderButtonConnect()}
        {renderButtonMyAccount()}
      </>
    );
  };

  const renderHeaderMobile = () => {
    return (
      <>
        <div>
          <a href={"/"} className={styles.navbarLink}>
            <img
              src={logo}
              onLoad={() => setLogoLoading(false)}
              style={logoLoading ? { display: "none" } : {}}
              alt="logo"
            />
          </a>
        </div>
        <div
          className={styles.rightHeadMobile}
          onClick={() => setOpenSideBar(true)}
        >
          <div />
          <div />
          <div />
        </div>
        <div className={styles.rightBarMobile + (openSideBar ? " active" : "")}>
          <div className={styles.logoMobile}>
            <a href="/" style={{ padding: 0 }}>
              <img src={logo} alt="logo" />
            </a>
            <img
              src={iconClose}
              onClick={() => setOpenSideBar(false)}
              alt="icon"
            />
          </div>
          <div className={styles.navbarMobile}>
            {renderButtonPool()}
            {renderButtonStaking()}
            {renderButtonLearn()}
            {renderButtonBuyRWA()}
            {renderButtonNetwork()}
            {renderButtonConnect()}
            {renderButtonMyAccount()}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.header}>
        <div className={styles.container}>
          {!isShowBanner && (
            <div className="banner-top">
              <p>
                Welcome to the TestNet RWA Launchpad, this product is for
                testing purposes only. The $RWA token on this platform is a test
                version and does not relate to the real $RWA token. When KYC'ed
                on this platform you will automatically gain Bronze tier on the
                MainNet product, which will go live in the coming weeks
              </p>
              <div>
                <button
                  onClick={() => {
                    localStorage.setItem("isShowBanner", "true");
                    setIsShowBanner("false");
                  }}
                >
                  x
                </button>
              </div>
            </div>
          )}
          <div className={styles.navBar}>
            {isTablet ? renderHeader() : renderHeaderMobile()}
          </div>
        </div>
      </div>

      <HeaderContext.Provider value={{ agreedTerms, setAgreedTerms }}>
        <ConnectWalletModal
          opened={openConnectWallet as boolean}
          handleClose={handleConnectWalletClose}
        />
        {/* <EnterRefCodeModal
          opened={!!openEnterRefCode}
          handleClose={handleEnterRefCodeClose}
          currentAccount={currentAccount}
          referralProgramUrl={referLinkPublic.referral_program ?? ""}
        /> */}
        <AppNetworkSwitch
          opened={switchNetworkDialog}
          handleClose={() => setSwitchNetworkDialog(false)}
        />
        <WalletDisconnect
          opened={disconnectDialog}
          handleClose={() => {
            setDisconnectDialog(false);
            setAgreedTerms(false);
            setOpenSideBar(false);
          }}
          currentWallet={currentConnectedWallet}
        />
        <SignRequiredModal opened={refreshing} />
      </HeaderContext.Provider>

      <div className={styles.breadcrumbs}>
        {!(!pathname || pathname === "/") && (
          <BreadCrumbComponent listSubpath={listSubpath} />
        )}
      </div>

      {renderError()}
      <a
        href="https://vcb6zxqhn5v.typeform.com/to/fOm2gWOh"
        target="_blank"
        className={styles.reportBug}
        rel="noreferrer"
      >
        <img src="/images/report_bug.svg" alt="report bug" />
        <p>Bug Bounty</p>
      </a>
    </>
  );
};

const BreadCrumbComponent = (props: any) => {
  const { poolDetails } = usePoolDetails(
    props?.listSubpath?.[1]?.url === "pools"
      ? props?.listSubpath?.[2]?.url
      : "0"
  );

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props?.listSubpath?.map((subpath: any, index: number) => {
        if (!subpath?.url) {
          return (
            <Link key={index} to="/">
              Home
            </Link>
          );
        }
        if (index === props?.listSubpath?.length - 1) {
          return (
            <Typography key={index}>
              {poolDetails?.title || subpath?.name}
            </Typography>
          );
        }
        if (subpath?.isDisable) {
          return <Typography key={index}>{subpath?.name}</Typography>;
        }
        return (
          <Link key={index} to={`/${subpath?.url}`}>
            {subpath?.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default withWidth()(HeaderDefaultLayout);
